import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  if (typeof window !== 'undefined') {
    window.location = '/';
  }
  return null;
}

export default NotFoundPage
